.cd-search-container {
  position: relative;
  width: 80%;
  /* display: inline-block; */
}

/* .cd-search-keyword-input{
    display: inline-block;
} */

.cd-search-result-list {
  position: absolute;
  width: 40%;
  top: 41px;
  border: 1px solid rgba(0, 0, 0, 0.411);
  border-radius: 3px;
  background-color: white;
}

.cd-search-result-list-element {
  padding: 8px 10px;
  cursor: pointer;
  transition-property: all;
  transition-duration: 0.2s;
  color: black;
}

.cd-search-result-list-element:hover {
  background-color: #195600;
  color: white;
  padding: 8px 12px;
}
