.cd-navbar-container {
  text-align: right;
}

.cd-dashboard-right-section {
  float: right;
  width: 87%;
}

.width_center {
  width: 1000px;
  margin: 0 auto;
}

.cd-navbar-wrapper {
  /* display: block; */
  padding-right: 10px;
  padding-top: 10px;
}

.cd-dashboard-left-section {
  /* background-color: #001c2b; */
  float: left;
  /* color: #ffff; */
  width: 12%;
  height: 100vh;
  border-right: 1px solid #000000;
}

.cd-dashboard-content {
  height: 90vh;
  overflow-y: auto;
}

.cd-dashboard-strip {
  text-align: center;
  padding: 15px 0px;
}

.cd-dashboard-strip-controls {
  /* margin-top: 15px; */
}

.cd-menu-item {
  /* margin-bottom: 15px; */
  padding: 12px 15px;
}

.cd-navbar-element-group {
  list-style: none;
  float: right;
}

.cd-navbar-element-group > :last-child {
  margin-right: 0px;
}

.cd-navbar-element > :first-child {
  margin: 0;
  padding: 0;
}

.cd-navbar-element {
  float: left;
  margin-right: 30px;
}

.cd-link {
  text-decoration: none;
  /* padding: 10px; */
}

.cd-user-control-section {
  width: 130px;
  text-align: left;
  position: relative;
}

.cd-user-control-btn {
  background-color: #ffff;
  /*white*/
  border: 0;
  cursor: pointer;
}

.cd-user-icon {
  border: 1px solid black;
  border-radius: 50%;
}

.cd-user-controls {
  margin-top: 5px;
  text-align: left;
  border-radius: 9px;
  position: absolute;
}

.cd-user-control-element {
  text-decoration: none;
  color: black;
  /* color: #000000; */
  cursor: pointer;
}

.cd-subscription-container {
  margin-top: 10px;
}

.cd-subscription-container > :last-child {
  margin-bottom: 0px;
}

.cd-subscription-list-item {
  margin-bottom: 10px;
}

.cd-backend-users-list-container {
  margin-top: 5px;
}

.cd-backend-users-list-element {
  margin-bottom: 10px;
}

.cd-active {
  background-color: #195600;
}

.cd-active > :first-child {
  color: white;
}

.cd-features-list-selector {
  display: block;
}

.cd-features-list-selector-left {
}

.cd-features-list-selector-right {
  width: 50px;
}

.cd-front-end-user-list-container {
  margin: 5px 0px;
}

.cd-user-profile-thumbnail {
  height: 50px;
  max-width: 150px;
  /* width: 150px; */
  border: 2px solid black;
  border-radius: 50%;
}

.cd-front-end-user-list-element {
  margin-bottom: 10px;
}

.cd-tax-models-list-container {
  margin-top: 10px;
}

.cd-tax-model-list {
  margin-bottom: 5px;
}

.cd-tax-models-list-container > :last-child {
  margin-bottom: 0px;
}

.cd-regions-list-container {
  margin: 5px 0px;
}

.cd-regions-list-container > :last-child {
  margin-bottom: 0px;
}

.cd-regions-list {
  margin-bottom: 5px;
}

.cd-fields-list-container {
  margin: 5px 0px;
}

.cd-fields-list-container > :last-child {
  margin-bottom: 0px;
}

.cd-fields-list {
  margin-bottom: 5px;
}

.cd-coupons-list-container {
  margin-top: 10px;
}

.cd-coupons-list-container > :last-child {
  margin-bottom: 0;
}

.cd-coupons-list-item {
  margin-bottom: 10px;
}

.cd-border {
  border: 1px solid black;
}
