.cd_username_container {
}

.cd_password_container {
}
.cd_not_have_account_container {
  margin-top: 10px;
}
.cd_sign_up_link {
  margin-top: 2px;
  text-decoration: none;
}
