/* The Modal (background) */
.cd_modal {
  /* Hidden by default */
  position: fixed;
  /* Stay in place */
  z-index: 1;
  /* Sit on top */
  left: 0;
  top: 0;
  width: 100%;
  /* Full width */
  height: 100%;
  /* Full height */
  overflow: auto;
  /* Enable scroll if needed */
  background-color: #00000070;
  /* Black w/ opacity */
}

/* Modal Content/Box */
.cd_modal-content {
  background-color: #fefefe;
  margin: 10px auto 0px auto;
  /* 15% from the top and centered */
  padding: 20px;
  border: 1px solid #aca0a0;
  border-radius: 10px;
  width: 35%;
  /* Could be more or less, depending on screen size */
  animation: move 0.3s ease-out forwards;
  transition-property: all;
  transition-duration: 1s;
}

@keyframes move {
  0% {
    margin-top: 0px;
    opacity: 0;
  }

  100% {
    margin-top: 20px;
    opacity: 1;
  }
}

/* The Close Button */
.cd_modal_close {
  color: #aaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.cd_modal_close:hover,
.cd_modal_close:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}
