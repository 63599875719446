*,
html {
  margin: 0px;
  padding: 0px;
}

.clearfix:after {
  visibility: hidden;
  display: block;
  font-size: 0;
  content: " ";
  clear: both;
  height: 0;
}

* html .clearfix {
  zoom: 1;
}

/* IE6 */
*:first-child + html .clearfix {
  zoom: 1;
}

/* IE7 */

.cd-card-container {
  text-align: center;
  margin-top: 10px;
}

.cd-card {
  display: inline-block;
  max-width: 30%;
}

.cd-user-role-head {
  font-size: 24px;
  margin-bottom: 10px;
}

.cd-wrapper {
  display: block;
}

.cd-input-container {
  margin-bottom: 10px;
  text-align: left;
}

.cd-input-label {
  text-align: left;
  display: block;
  font-size: 16px;
  margin-bottom: 5px;
}

.cd-input-field {
  text-align: left;
  padding: 10px;
  font-size: 16px;
}

.cd-btn-container {
  margin-top: 15px;
}

.cd-btn {
  padding: 7px 15px;
  font-size: 16px;
  cursor: pointer;
}

.cd-common-anchor-tag {
  text-decoration: none;
}

.cd-have-account-container {
  margin-top: 10px;
}

.cd-msg-container {
  margin-top: 15px;
}

.cd-err-msg {
  color: red;
}

.cd-success-msg {
  color: #008000;
  /*Green*/
}

.languageDropdown {
  margin-bottom: 10px;
}

.cd-custom-select {
  padding: 10px 5px;
}

.cd-email-verification-msg {
  color: #008000;
  /*green*/
  text-align: center;
}

.cd-loader {
  border: 4px solid #00b318;
  border-top: 4px solid #9cdd9c;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  animation: spin 1s linear infinite;
  margin: 10px auto;
}

.cd-loader-2 {
  border: 7px solid;
  position: absolute;
  top: 2px;
  border-color: rgba(255, 255, 255, 0.15) rgba(255, 255, 255, 0.25) rgba(255, 255, 255, 0.35)
    rgba(255, 255, 255, 0.5);
  border-radius: 50%;
  display: inline-block;
  margin: 0 auto;
  box-sizing: border-box;
  animation: animloader 1s linear infinite;
}

.cd-eye-button-parent {
  position: relative;
}

.cd-eye-button-img {
  position: absolute;
  cursor: pointer;
  padding: 3px;
  height: 15px;
  bottom: 10px;
  right: 14px;
}

@keyframes animloader {
  0% {
    border-color: rgba(0, 0, 0, 0.15) rgba(0, 0, 0, 0.25) rgba(0, 0, 0, 0.35) rgba(0, 0, 0, 0.75);
  }

  33% {
    border-color: rgba(0, 0, 0, 0.75) rgba(0, 0, 0, 0.15) rgba(0, 0, 0, 0.25) rgba(0, 0, 0, 0.35);
  }

  66% {
    border-color: rgba(0, 0, 0, 0.35) rgba(0, 0, 0, 0.75) rgba(0, 0, 0, 0.15) rgba(0, 0, 0, 0.25);
  }

  100% {
    border-color: rgba(0, 0, 0, 0.25) rgba(0, 0, 0, 0.35) rgba(0, 0, 0, 0.75) rgba(0, 0, 0, 0.15);
  }
}

.cd-user-profile-photo-container {
  margin: 10px 0px;
}

.cd-user-profile-photo {
  height: 150px;
  max-width: 150px;
  /* width: 150px; */
  border: 2px solid black;
  border-radius: 50%;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.cd-pagination-container {
  text-align: center;
}

.cd-pagination-wrapper {
  display: inline-block;
  padding: 10px 20px;
  margin: 5px 0px;
  background-color: rgba(204, 204, 204, 0.288);
  border-radius: 10px;
}

.cd-pagination-wrapper > :last-child {
  margin-right: 0px;
}

.cd-pagination-element {
  float: left;
  cursor: pointer;
  border: 1px solid black;
  border-radius: 5px;
  padding: 2px 10px;
  margin-right: 10px;
}

.cd-go-to-page-container {
  text-align: center;
}

.disable-pointer {
  pointer-events: none;
}

.cd-go-to-page-wrapper {
  display: inline-block;
  padding: 10px 10px;
  background-color: rgba(204, 204, 204, 0.288);
  border-radius: 10px;
}

.cd-permission-list-element {
  margin-bottom: 10px;
}

.cd-permissions-list-container > :last-child {
  margin-bottom: 0px;
}

.cd-connection-off-msg {
  animation: connection-lost-msg 1s ease-out forwards;
}

.not-appeared {
  opacity: 0;
  transition: opacity 0.2s;
}

.appeared {
  opacity: 1;
  transition: opacity 0.2s;
}

.appeared-top-bar {
  opacity: 1;
  transition: opacity 0.5s;
  position: relative;
}
.not-appeared-top-bar {
  opacity: 0;
  transition: opacity 0.5s;
  position: relative;
}

@keyframes connection-lost-msg {
  0% {
    opacity: 0;
    padding: 0px;
    height: 0;
    background-color: red;
    color: white;
  }
  50% {
    height: 50%;
    opacity: 0.5;
  }
  100% {
    opacity: 1;
    padding: 10px;
    height: auto;
    background-color: red;
    color: white;
  }
}

.cd-connection-on-msg {
  animation: connection-back-msg 1s ease-out forwards;
}
@keyframes connection-back-msg {
  0% {
    opacity: 0;
    padding: 0px;
    height: 0;
    background-color: green;
    color: white;
  }
  50% {
    height: 50%;
    opacity: 0.5;
  }
  100% {
    opacity: 1;
    padding: 10px;
    height: auto;
    background-color: green;
    color: white;
  }
}
